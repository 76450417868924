.newAdvisoryMain{
    position: relative;
}

.headerAdvisory{
    background-color: #0081ac;
    padding: 1rem;
    border-radius: 1rem;
    width: 50%;
    margin: 0 auto 0 auto;
}

.headerAdvisory h3,h4{
    color: white;
    margin-top: 0 !important;
}

.advisoryInfo{
    text-align: center;
    color: white;
    margin: 0 1.5rem 0 1.5rem;
}



.progress-step{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.step{
    border-radius: 100%;
    height: .8rem;
    width: .8rem;
    cursor: pointer;
}

.active{
    background-color: white;
}

.inactive{
    background-color: #3facc8;
}

.step-line{
    height: .3rem;
    width: 1.5rem;
    align-self: center;
}

.iconBack{
    position: absolute;
    left: 5px;
    font-size: 1.3rem;
    top: .8rem;
    color: #3facc8;
    text-align: center;
}

.greyContainer{
    background-color: #ededee;
    //width: 100%;
    height: 100vh;
    position: absolute;
    margin-top: 3rem;
}

.cardContainer{
    width: 50%;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.cardContent{
    background-color: white;
    border-radius: 1rem;
    padding: 1rem 1.5rem 1rem .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.ant-form-item-label{
    margin-left: .5rem;
    padding-bottom: 0;
}

.ant-input-disabled{
    border-radius: 1rem !important;
    padding: .6rem 0rem .6rem .5rem;
}

.ant-select-selector{
    border-radius: 1rem !important;
}

.advisorCard{
    background-color: white;
    border-radius: 1rem;
    padding: .7rem;
    cursor: pointer;
    margin-bottom: .5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.advisorContainer{
    display: flex;
}

.advisorContent{
    margin-left: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.advisorContainer img{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    object-fit: cover;
}

.advisorCard p{
    margin: 0;
    font-size: 1.2rem;
}

.advisorCard a{
    font-size: 1rem;
}

.advisorContent span{
    display: flex;
    margin: auto .2rem auto 0;
}

.timePicker{
    display: table;
    margin-right: auto;
    margin-left: auto;
}

.btnConfirmation{
    color: white;
    width: 95%;
    margin-top: 2rem !important;
    background-color: #ee2c70;
    padding-top: .5rem;
    padding-bottom: 2rem;
    border-radius: 2rem !important;
    font-size: 1.2rem;
    margin-bottom: 2rem !important;
}

.btnConfirmation:hover{
    color: white;
    background-color: #ee2c70;
    border-color: #ee2c70;
}

.btnPink{
    border:none;
    color: white;
    width: 40%;
    margin-top: 2rem !important;
    background-color: #ee2c70;
    border-radius: 2rem !important;
    font-size: 1.2rem;
    height: 2.6rem;
    cursor: pointer;
}

.btnPink:hover{
    color: white;
    background-color: #ee2c70;
    border-color: #ee2c70;
}

.btnPink::selection{
    color: white;
    background-color: #ee2c70;
    border-color: #ee2c70;
}

.btn-finish{
    padding: 0 4rem 0 4rem;
}

.hourTag{
    border: solid .125rem #0081ac;
    border-radius: 3rem;
    font-size: 1rem;
    padding: .5rem 1rem;
    margin: .2rem;
    display: inline-flex;
}

.hourNotSelected{
    background-color: transparent;
    color: #0081ac;
}

.hourSelected{
    background-color: #0081ac;
    color: white;
}

.hourOccupied{
    background-color: #ee2c70;
    border-color: #ee2c70;
    color: white;
    text-decoration:line-through;
}

/* Calendar Widget */

.calendar_widget {
    margin-top: 2rem;
  }

  .calendar_header_content{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .calendar_widget .calendar_header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    background-color: white;
    padding: .5rem;
    border-radius: .5rem;
    margin-bottom: 1rem;
  }
  
  .calendar_widget .calendar_header .calendar_title {
    min-width: 300px;
  }
  
  .calendar_widget .calendar_header .calendar_title .h4 {
    margin-left: 2rem;
  }
  
  .calendar_widget .calendar_body {
    //padding: .5rem;
    border: 1px solid #ccc;
    border-radius: .5rem;
  }
  
  .calendar_widget .calendar_body .tr_days_name td {
    padding-bottom: .25rem;
  }
  
  .calendar_widget .calendar_body .tr_days_calendar td {
    padding-bottom: .7rem;
    font-size: .85rem;
    border: 0;
    color: #5e5d5d;
  }
  
  .calendar_widget .calendar_body .ul_course {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .li_appointment{
      width: 100%;
      background-color: #0099cc;
      border-radius: 1rem;
      padding: .5rem;
      text-align: center;
      color: white;
      margin-bottom: .5rem;
  }

  .appointment_divisor{
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.appointment_icons{
    display: flex;
    justify-content: space-evenly;
}

  .info_appointment{
      display: block;
  }
  
  .card_calendar {
    margin-bottom: .875rem;
  }
  
  .card_calendar .card_calendar_title {
    text-align: center;
    background: #8e7ef9;
    color: white;
    padding: .5rem;
    border-radius: .25rem;
  }
  
  .card_calendar .card_calendar_title .td_day {
    padding: 0;
    font-size: .75rem;
    border: 0;
    color: white;
  }

  #table_calendar{
      width: 100%;
  }

  .month_name{
      color:#0099cc;
      font-weight: bold;
      font-size: 1.5rem;
      border-right: solid;
      padding-right: .5rem;
      margin-right: .5rem;
  }

  .bg_white{
    background-color: #ffffff;
  }

  .bg_dark{
    background-color: #e5e5e5;
  }

  .bg_darker{
    background-color: #dcdcdc;
  }

  .calendar_colum{
    padding: .5rem;
  }

  .table_calendar_head{
      text-align: center;
      margin-bottom: 1rem;
  }

  .tr_days_name td{
      font-weight: bold;
      font-size: 1rem;
      padding-top: .5rem;
      width: 150px;
  }

  .grup_buttons{
      display: flex;

    justify-content: space-between;
  }

  .input_group_programming_advisory{
      display: flex;
      justify-content: space-between;
  }

  .ant-picker{
      border-radius: 1rem;
  }

  .day_switch{
    text-align: center;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: grid;
    place-content: space-evenly;
    font-size: 1.5rem;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
  }

.day_switch_active{
    background-color: #1890ff;
    color: white;
}

.day_switch_inactive{
    background-color: #bebebe;
    color: #8b8d8e;
}

.ant-modal-header, .ant-modal-title{
    background-color: #1890ff;
    color: white;
    font-weight: bold;
    text-align: center;
}

.add_advisory_programming{
    border: none;
    color: white;
    background-color: #0099cc;
    border-radius: 100% !important;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.config_availability{
    border: none;
    color: white;
    background-color: #0099cc;
    cursor: pointer;
    border-radius: 2rem !important;
    padding: 0 1.5rem;
}

.modalAdvisory .ant-modal-body{
    background-color: #ededee !important;
}

.current_date_now{
    background: #169e48;
    color: white;
    padding: .3rem .7rem;
    border-radius: 2rem;
}

.cardAdvisoryListMobile{
    background-color: white;
    border-radius: 1rem;
    display: none;
    justify-content: space-between;
    margin: .5rem;
}

.cardAdvisoryListMobile .dateName{
    border-radius: 100%;
    border: solid 3px #0099cc;
    color: #0099cc;
    display: grid;
    text-align: center;
    width: 100px;
}

.dateNumber{font-size: 2rem;
    margin: 0;
    padding: 0;
    font-weight: bold;}

.dateMonth{    padding: 0 0 1rem 0;
    margin: -.8rem;}

    .btn-group-advisory-list{
        background-color: #0099cc;
        border-radius: 0 1rem 1rem 0;
        padding: .5rem;
    }
    

@media (max-width: 470px) {
    .navBar{
        display: none;
    }
    
    .site-layout{
        padding: 0;
    }

    .greyContainer{
        width: 100%;
    }

    .cardContainer{
        position: absolute;
        width: 100%;
    }

    .advisoryInfo{
        color: white;
    }

    .iconBack{
        color: white;
    }

    .headerAdvisory{
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        width: 100%;
        margin: 0;
    }

    .calendar_title{
        display: grid;
        position: absolute;
        justify-items: center;
        width: 100%;
        left: 0;
    }

    .month_name {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
    }

    .calendar_buttons{
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 999;
    }

    .btn_current_week{
        display: block;
        margin-top: 1.2rem !important;
    }

    .calendar_header_content{
        width: 100%;
    }

    .calendar_widget .calendar_header .calendar_title .h4 {
        margin-left: 0;
    }

    .calendar_widget .calendar_header .calendar_title {
        min-width: 330px;
    }

    .calendar_widget .calendar_header{
        margin-right: .3rem;
        margin-left: .3rem;
        justify-content: center;
    }

    .calendar_body{
        display: none;
    }

    .cardAdvisoryListMobile{
        display: flex;
    }

}

