.navBar{
    color: white;
    background: var(--navbar-bg-color);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
}

.navBar ul{
    display: flex;
    color: white;
    background: transparent !important;
}

/*.nav-items{
    display: none;
}*/

.icon-logout{
    float: 'right';
    font-size: '2rem'; 
    margin:50%;
}

.site-layout{
    background-color: var(--background-color);
    padding-top:85px;
    padding-bottom:85px;
    padding-left:18px;
    padding-right:18px;

}

.site-layout h1,h3,h4{
    color: var(--primary);
}

.site-layout h1,h2,h3{
    font-size: 1.9rem;
    margin: 5px 0;
}

.site-layout h1,h2,h4{
    font-weight: bold;
}

.site-layout h1{
    margin-top: 1rem;
}

.site-layout h2{
    color: var(--secondary);
}

.site-layout h4{
    font-size: 1.3rem;
    margin-top: 3.2rem;
}

.footer {
    text-align: center;
    padding: 1rem 1.8rem;
    position: fixed;
    bottom: 0rem;
    width: 100%;
    height:  auto;
}

.user {
    text-align: left;
}

.create {
    text-align: right;
}


@media (max-width: 470px) {
    .site-layout{
        padding: 75px 20px;
    }
    
    .ant-layout-header{
        padding: 0 20px;
    }

    .site-layout h1 {
        margin-top: 0;
    }

    .site-layout h2{
        font-weight: lighter;
        font-size: 1.3rem !important;
    }

    .site-layout h3{
        line-height: 2.4rem;
    }

    .site-layout h4{
        margin-top: 1.2rem;
    }
}

@media (max-width: 575px) {
    .user {
        text-align: center;
    }
    
    .create {
        text-align: center;
        padding-top: 0.4rem;
    }
}