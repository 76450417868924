.welcome-content{
    display: grid;
    color:white;

    font-family: 'Foco', sans-serif !important;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--primary);
    height: 63px;
    padding: 0 30px;
    z-index: 999;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
}

.navbar img{
    float: left;
    padding: 10px 0;
    position: relative;
    top: 10px;
    z-index: 2050;
}

.nav-container{
    padding-left: 10rem;
}
  
.navbar a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 18.5px 18px 14px;
    text-decoration: none;
    font-size: 17px;
}

.navbar a:hover {
    background-color: rgba(0,117,176,0.5);
    border-bottom: solid 4px #fff;
}

.section-welcome{
    width: 100%;
    height: 600px;
    position: relative;
}

.bg-welcome{    
    background-image: url("../imgs/bg_welcome.jpg");
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    filter: brightness(0.5);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    //background-size: cover;
    //background-repeat: no-repeat;

}

.welcome-title{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.welcome-buttons{
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
}

.welcome-title h1{
    text-align: center;
    margin-top: 180px;
    color: white;
    font-size: 4rem;
    font-weight: bold;
    line-height: 80px;
}

.section-what-can-i-do{
    width: 100%;
    height: 600px;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4.5rem;
}

.section-what-can-i-do h2{
    color: white;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
}

.actions-subsection{
    display: flex;
    justify-content: space-around;
    margin-top: 70px;
}

.action-item{
    text-align: center;
    padding-right: 2rem;
    padding-left: 2rem;
}

.action-item img{
    height: 90px;
    width: 90px;
    margin-bottom: 20px;
}


.action-item h3{
    color: white;
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
}

.action-item p{
    font-size: 1.3rem;
    margin: 0;
    margin-top: 5px;
    line-height: 27px;
}

.separator{
    width: 80%;
    background-color: white;
    height: 2px;
    margin: 2rem auto 2rem auto;
    display: none;
}

.section-get-in{
    width: 100%;
    position: relative;
    height: 360px;
    color:var(--secondary);
    background-color: white;
    padding-top: 60px;
    padding-left: 2rem;
    padding-right: 2rem;
}

.img-get-in{
    width: 100%;
    height: 100%;
    height: 15rem;
    margin-right: 50px;
    background-image: url("../imgs/get-in.jpg");
    background-size: cover;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
}

.section-get-in h3{
    color: #4c4c4c;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 0;
}

.info-get-in{
    font-size: 1.5rem;
    display: flex;
    align-items: end;
    margin-top: 20px;
}

.info-get-in img{
    width: 35px;
    height: 35px;
    margin-left: 2rem;
}

.info-get-in p{
    font-size: 1.7rem;
    margin: 0;
    line-height: 28px;
    color: #4c4c4c;
}


footer{
    background-color: #0099cc;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    height: 50px;
}

footer p{
    margin: 0;
    padding: 0;
    align-self: center;
}

.menu-bar{
    width: 100%;
    height: 800px;
    background-color: #0692c4;
    position: fixed;
    z-index: 9;
}

@media (max-width: 901px) {
    
    .section-what-can-i-do{
        width: 100%;
        height: 500px;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 3rem;
    }

    .section-what-can-i-do h2{
        color: white;
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
    }

    .action-item{
        text-align: center;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    .action-item img{
        height: 2rem;
        width: 2rem;
        margin-bottom: 1rem;
        margin-right: 0.5rem;
    }

    .action-item h3{
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0;
    }

    .action-item p{
        font-size: 1rem;
        margin: 0;
        margin-top: 5px;
        line-height: 27px;
    }

    .actions-subsection{
        margin-top: 3rem;
    }
}

@media (max-width: 700px) {
   
    .section-welcome{
        width: 100%;
        height: 31rem;
    }

    .welcome-buttons{
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        width: 400px;
    }

    .welcome-title h1{
        text-align: center;
        margin-top: 8rem;
        color: white;
        font-size: 3rem;
        font-weight: bold;
        line-height: 5rem;
    }
}

@media (max-width: 692px) {
    .section-what-can-i-do{
        width: 100%;
        height: 33rem;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 3rem;
    }

    .section-what-can-i-do h2{
        color: white;
        text-align: center;
        font-size: 2.2rem;
        font-weight: bold;
    }

    .action-item{
        text-align: center;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        padding-top: 1.5rem;
    }

    .action-item h3 {
        font-size: 1.3rem;
    }

    .action-item p {
        font-size: 0.9rem;
        line-height: 1.5rem;
    }
}



@media (max-width: 648px) {
    .navbar a{
        display: none;
    }
}

@media (max-width: 576px) {
    .actions-subsection {
        margin-top: 0.1rem;
    }

    .section-what-can-i-do{
        width: 100%;
        height: 40rem;
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 2rem;
    }

    .section-what-can-i-do h2{
        color: white;
        text-align: center;
        font-size: 2.2rem;
        font-weight: bold;
    }

    .section-get-in {
        width: 100%;
        position: relative;
        height: 27rem;
        background-color: white;
        padding-top: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .img-get-in {
        width: 100%;
        height: 15rem;
        background-image: url("../imgs/get-in.jpg");
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .action-item img {
        height: 1.5rem;
        width: 1.5rem;
        margin-bottom: 1rem;
        margin-right: 0.5rem;
    }

    footer{
        font-size: 1rem;
    }
}


@media (max-width: 470px) {
    .welcome-content{
        display: inline-grid;
    }

    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: rgba(0,153,204,.9);
        height: 63px;
        padding: 0 30px;
        z-index: 999;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    }

    .welcome-title h1 {
        text-align: center;
        margin-top: 9rem;
        color: white;
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 3.5rem;
    }

    .welcome-buttons{
        display: grid;
        width: auto;
        justify-content: center;
    }

    .section-welcome{
        width: 100%;
        height: 30rem;
        position: relative;
    }

    .btn-orange {
        text-decoration: none;
        background-color: #ff6319;
        color: white;
        border: 0;
        border-radius: 5px;
        padding: .6rem 2.5rem;
        font-size: 0.6rem;
        cursor: pointer;
        outline: none;
        margin-bottom: 1.5rem;
    }

}

@media (max-width: 400px) {

    .welcome-title {
        width: 100%;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .welcome-title h1 {
        text-align: center;
        margin-top: 7rem;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        line-height: 3rem;
    }
    

    .welcome-buttons{
        display: grid;
        width: auto;
        justify-content: center;
    }


    .section-welcome {
        width: 100%;
        height: 26rem;
        position: relative;
    }

    .section-what-can-i-do {
        width: 100%;
        height: 52rem;
    }

    .section-get-in {
        width: 100%;
        height: 32rem;
    }

    .btn-orange {
        text-decoration: none;
        background-color: #ff6319;
        color: white;
        border: 0;
        border-radius: 5px;
        padding: .6rem 2.5rem;
        font-size: 0.6rem;
        cursor: pointer;
        outline: none;
        margin-bottom: 1.5rem;
    }

    .info-get-in img {
        margin-left: 0rem;
    }

    footer {
        font-size: 0.7rem;
        height: 2.5rem;
    }

}