.login-content{
    display: grid;
}

.card-login{
    background-color: white;
    margin: 0 auto 0 auto;
    width: 25rem;
    border-radius: 1rem;
    padding: 2rem;
    height: fit-content;
}

.user_input{
    border-radius: .8rem !important;
}

.password_input{
    border-radius: .8rem;
}

.btn-forgot-back{
    text-decoration: none;
    background-color: #0099cc;
    color: white !important;
    border: 0;
    border-radius: .5rem !important;
    padding: .6rem 2.5rem;
    font-size: .9rem;
    cursor: pointer;
}

@media (max-width: 470px) {
    .card-login{
        width: 90%;
    }
}