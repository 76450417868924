@import url('https://fonts.cdnfonts.com/css/foco');

:root{
    --primary: #0099cc;
    --secondary: #4a4a4a;
    --background-color: #ededee;
    --navbar-bg-color: linear-gradient(-90deg, #d03f8e 1%, #019cde 30%, #0099CC 70%);
}


body {
    display: block;
    margin: 0;
    font-family: 'Foco', sans-serif !important;
   
}


.background-gradient{
    background: linear-gradient(90deg, #d03f8e 1%, #019cde 30%, #0099CC 70%);
}

.background-gradient-vertical{
    background: linear-gradient(180deg, #d03f8e 1%, #019cde 30%, #0099CC 70%);
}

.btn-orange{    
    text-decoration: none;
    background-color: #ff6319;
    color: white;
    border: 0;
    border-radius: 5px;
    padding: .8rem 3rem;
    font-size: 1.1rem;
    cursor: pointer;
    outline:none;
}

.btn-orange:hover{    
    color: white;
    background-color: #ec560e;
}

.btn-green{    
    text-decoration: none;
    background-color: #169e48;
    color: white;
    border: 0;
    border-radius: 8px;
    padding: .6rem 2rem;
    font-size: .9rem;
    cursor: pointer;
    outline:none;
    margin: 0 auto 0 auto;
}

.btn-green:hover{    
    color: white;
    background-color: #13863d;
}

.grey-link{
    color: var(--secondary);
    font-size: 1rem;
    display: block;
    margin: 0 auto 1rem auto;
}

.green-link{
    color: #13863d;
    font-size: 1rem;
    display: block;
    margin-top: 1rem;
}

.logo{
    float: left;
    width: 120px;
    height: 31px;
    margin: 20px 24px 16px 0;
    background-image: url("../imgs/logo_pacifico_small.png");
    background-repeat: no-repeat;
    background-size: contain;
    //background-image: url("../../imgs/logo_pacifico_small.jpg");
}

.site-layout .site-layout-background {
    background: #fff;
}

.logo-ini{
    display: block;
    margin: 2rem auto 2rem auto;
    width: 15rem;
}

.widgets{
    display: grid;
    grid-template-columns: 350px 350px;
    grid-template-rows: auto;
    row-gap: 15px;
    column-gap: 10px;
    user-select: none;
}

.widget{
    cursor: pointer;
    background-color: white;
    width: 330px;
    height: 90px;
    border-radius: 1rem;
    //margin: 0 2rem 1rem 0;
    display: flex;
    align-items: flex-end;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}



.widget:hover{
    transform: scale(1.03);
}

.widget img{
    width: 50px;
    height: 50px;
}

.widget p{
    color: #0799cc;
    font-size: 1.1rem;
    margin: auto;
    font-weight: bold;
    text-align: center;
}

.icon-container{
    border: solid 2px #0099cc;
    border-radius: 5rem;
    width: 70px;
    height: 70px;
    margin: auto 1rem auto 1rem;
    padding: .5rem;
    opacity: .7;
}

.buttons-group{
    display: inline;
    float: left;
}

.btn-blue{
    text-decoration: none;
    background-color: var(--primary);
    color: white !important;
    border: 0;
    border-radius: 5rem !important;
    padding: .6rem 2.5rem;
    font-size: .9rem;
    cursor: pointer;
}

.btn-blue:hover{
    background-color: rgba(0, 153, 204, .8);
}

.btn-blue-outline:hover{
    opacity: .8;
}

.btn-blue-outline{
    text-decoration: none;
    color: var(--primary);
    border: solid 1px;
    border-radius: 5rem !important;
    padding: .6rem 2.5rem;
    font-size: .9rem;
    cursor: pointer;
}

#root{
    height: 100%;
}

.ant-layout{
    min-height: 100% !important;
}

@media (max-width: 470px) {

    .btn-orange{
        font-size: 2.9rem;
        display: block;
        padding: 1.5rem;
        text-align: center;
        margin-bottom: 3rem;
        border-radius: 1rem;
    }

    :root{
        --background-color: #0081ac;
        --navbar-bg-color:  #0081ac;
        --primary: white;
        --secondary: white;
    }

    .widgets{
        grid-template-columns: 50% 50%;
    }

    .widget{
        display: block;
        width: 100%;
        height: 130px;
    }

    .icon-container{
        margin: .5rem auto;
    }

    .widget p{
        font-size: .9rem;
        font-weight: lighter;
    }
}



@media (max-width: 470px) {
    .show-autodiagnostic{
        display: none;
    }
    .mobile-show-autodiagnostic{
        display:unset !important;
    }
    .col-result{
        width: 300px !important;
    }
    .col-result .widget-result{
        cursor: pointer;
        width: 300px !important;
      
        margin: 4px !important;
        border-radius: 15px !important;
        padding-top: 0px !important;
        padding-bottom: 6px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;


        position: relative  ;
        color: rgb(39, 38, 38);
        box-shadow: rgba(101, 101, 134, 0.25) 0px 6px 6px -2px, rgba(79, 76, 76, 0.3) 0px 3px 7px -3px;
    }

  
    .col-result .table-results td{
        border: solid 1px;
         border-color: rgb(32, 32, 32) ;
         padding: 1px;
         font-size: 8px;
    }
    
    .col-result  .table-results thead td{
        text-align: center;
        border: solid 0.5px;
        border-color: rgb(32, 32, 32) ;
        background-color:  #1b9bcc ;
        color: white;
    }

    .danger-secondary{
        border: solid 1px;
        border-color: rgb(220, 75, 130) !important;
        width: 300px;
        color:rgb(220, 75, 130) !important;
    }
    .ant-btn-danger{
        width: 300px !important;
    }
}

.widget-checklist{
    cursor: pointer;
    width: 300px;
    height: 50px !important;
    margin: 10px !important;
    border-radius: 15px !important;
    padding-top: 0px !important;
    padding-bottom: 6px !important;
    position: relative  ;
    // display: flex;
    // align-items: flex-end;
    color: rgb(39, 38, 38);
    box-shadow: rgba(101, 101, 134, 0.25) 0px 6px 6px -2px, rgba(79, 76, 76, 0.3) 0px 3px 7px -3px;
}
.widget-result{
    cursor: pointer;
  
    margin: 10px !important;
    border-radius: 15px !important;
    padding-top: 0px !important;
    padding-bottom: 6px !important;
    position: relative  ;
    color: rgb(39, 38, 38);
    box-shadow: rgba(101, 101, 134, 0.25) 0px 6px 6px -2px, rgba(79, 76, 76, 0.3) 0px 3px 7px -3px;
}
.widget-checklist a{
    cursor: pointer;
    
    color: rgb(39, 38, 38);
}



.table-results td{
    border: solid 1px;
     border-color: rgb(32, 32, 32) ;
     padding: 4px;
}

.table-results thead td{
    text-align: center;
    border: solid 1px;
    border-color: rgb(32, 32, 32) ;
     background-color:  #1b9bcc ;
      color: white;
}

.danger-secondary{
    border: solid 1px;
    border-color: rgb(220, 75, 130) !important;
    width: 180px;
    color:rgb(220, 75, 130) !important;
}
.ant-btn-danger{
    width: 180px !important;

}

.checkbox-show{
    width: 1.3em;
    height: 1.3em;
    background-color: rgb(116, 112, 112);
    border-radius: 50%;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-show:checked{
    background-color: #1b9bcc;
    border: 2px solid rgb(220, 218, 218);


}


.card-activate{
    width: 360px;
    height: 500px !important;
    margin: 10px !important;
    border-radius: 15px !important;
    padding-top: 0px !important;
    padding-bottom: 6px !important;
    position: relative  ;
    // display: flex;
    // align-items: flex-end;
    color: rgb(39, 38, 38);
    box-shadow: rgba(101, 101, 134, 0.25) 0px 6px 6px -2px, rgba(79, 76, 76, 0.3) 0px 3px 7px -3px;
}

.card-question{
    width: 100%;
    margin: 10px !important;
    border-radius: 15px !important;
    padding-top: 0px !important;
    padding-bottom: 6px !important;
    position: relative  ;
  
}
.ul-navbar .ant-menu-item:hover{
    color: #ededee!important;
    border-bottom: 2px solid #4a4a4a!important;
}
.ul-navbar .ant-menu-selected{
    color: #ededee!important;
    border-bottom: 2px solid #4a4a4a!important;
}
.ul-navbar .ant-menu-item a, .ul-navbar .ant-menu-item a:hover{
    color: #ededee!important;
}

.ant-btn-icon-only.ant-btn-sm {
    width: 24px!important;
}
.section_buttons_group{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2.4rem 0 1rem 0;
}
.buttons-group{
    .ant-picker{ margin-right: .5rem;}
    .ant-select{ margin-right: .5rem;}
}
@media (max-width: 640px) {

    .section_buttons{
        text-align: center;
        .ant-col{
            margin-bottom: .5rem;
        }
    }
    .btn-primary{
        color: #fff;
        background: #0081ac!important;
        border-color: #0081ac!important;
    }
    .section_buttons_group{
        justify-content: center;
    }
    .buttons-group{
        text-align:center;
        .ant-picker{
            width: 230px;
            margin-bottom: .5rem;
            margin-right: 0;
        }
        .ant-select{
            margin-bottom: .5rem;
            margin-right: 0;
        }
    }
}

.label-autodiagnostic{
    font-size: 1rem;
    margin-left: 0.7rem;
}

@media (max-width: 470px) {
    .title_page{
        color: #0081ac!important;
    }
    .title_welcome{
        color: white!important;
    }
    .label-autodiagnostic{
        text-align: center;
    }

    .label-autodiagnostic span{
        color: white;
    }

}



  .tabs-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tab-item{
    min-width: 260px;
    padding: .3rem 0;
    border-radius: .5rem .5rem 0 0;
    margin-right: .5rem;
    font-size: 1rem;
    border: solid 2px  #0099cc;
    color:  #0099cc;
    cursor: pointer;
    user-select: none;
    text-align: center;
    margin-bottom:.5rem;
}

.tab-item-active{
    background-color:  #0099cc;
    color: white;
}


@media (max-width: 470px) {

    .tab-item{
        min-width: 260px;
        padding: .3rem 0;
        border-radius: .5rem .5rem 0 0;
        margin-right: .5rem;
        font-size: 1rem;
        border: solid 2px  white;
        color:  white;
        cursor: pointer;
        user-select: none;
        text-align: center;
        margin-bottom:.5rem;
    }
    
    .tab-item-active{
        background-color:  #0099cc;
        color: white;
    }
    


}