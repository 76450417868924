.body{
    background: linear-gradient(to right, rgb(201, 63, 185) ,#1b9bcc , #1b9bcc );
    height : 100vh;
   
    font-family: Arial, Helvetica, sans-serif;


}

input{
    border-radius: 10px !important;
    margin: 7px !important;
    color: rgb(119, 117, 117) !important;
    font-size: 12px !important;
}
input,button:focus{
    outline: none;    
}
.title-primary{
    color:rgb(78, 158, 214);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;
    text-align: center;

}
.title-secondary{
    color:rgb(78, 158, 214);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: left;
    font-weight: bold;

}

.card{
    background-color:#fff;
    border-radius: 20px !important;
    width: 40%;
    display:inline-block !important;
    justify-content: center !important;
    margin: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;

}

button{
    margin: 6px !important;
    border-radius: 10px !important;


}
.btn-success{
    background-color: rgb(35, 121, 38)!important;
    color: #fff !important;
    border:none !important;
}